import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import { BffWorkingHours, Facility } from '@/types/generated';
import {
	getWorkingHours,
	getWorkingHoursFromBff,
	groupDays,
	resolveContentVariable,
	resolvePhoneNumberFormat,
} from '@/utils';
import { useAppContext, useFacilityContext } from '@/context';
import TAGButton from '@/components/TAGButton';
import { Maybe } from '@/types';
import TAGLink from '@/components/TAGLink';
import TAGSvgIcon from '@/components/TAGSvgIcon';
import OfficeHours from '@/components/Hero/ODP/OfficeHours';

import { addressLinkStyles, workingHoursBoxStyles, workingHoursContainerStyles } from './OfficeInfo.styles';

export interface IOfficeInfo {
	googleMapsBusinessQuery?: Maybe<string>;
	displayOfficeHoursInModal?: Maybe<boolean>;
}

const OfficeInfo = ({ googleMapsBusinessQuery, displayOfficeHoursInModal = false }: IOfficeInfo) => {
	const { officeInfo } = useFacilityContext(true);
	const { config } = useAppContext();
	const useFacilityBeffeDataSource = config.featureFlags.useFacilityBeffeDataSource;
	const [showHoursModal, setShowHoursModal] = useState(false);

	if (!officeInfo) return null;
	const address1 = officeInfo.address?.address1 as string;
	const city = officeInfo.address?.city as string;
	const officeState = officeInfo.address?.stateCode as string;
	const zip = officeInfo.address?.zipCode as string;
	const phoneNumber = officeInfo.technicalInfrastructure?.phoneNumber;
	const workingHours = useFacilityBeffeDataSource
		? getWorkingHoursFromBff((officeInfo.workingHours ?? []) as BffWorkingHours[], true)
		: getWorkingHours(officeInfo.workingHours ?? [], true);

	return (
		<Box width="100%">
			<TAGButton
				size="S"
				variant="tertiaryHC"
				href={resolveContentVariable(
					`${googleMapsBusinessQuery ?? config.googleMapBusinessQuery}`,
					officeInfo as Maybe<Facility>
				)}
				target="_blank"
				sx={addressLinkStyles}
			>
				{address1}
				<br />
				{city}, {officeState} {zip}
			</TAGButton>
			{phoneNumber && (
				<Grid item data-test-id="service_line_hero_item_phone">
					<TAGLink href={`tel:${phoneNumber.replace(/[- )(]/g, String)}`} noLinkStyle>
						<Typography variant="buttonLabel" color="accent02.main" display="flex" gap="0.375rem">
							<CallOutlinedIcon fontSize="small" data-test-id="location_details_phone_number_icon" />
							{resolvePhoneNumberFormat(phoneNumber)}
						</Typography>
					</TAGLink>
				</Grid>
			)}
			{!displayOfficeHoursInModal && !!officeInfo.workingHours?.length && (
				<Box sx={workingHoursBoxStyles}>
					<Typography color="text.light" variant="bodyLargeBold">
						Hours of Operation
					</Typography>
					<Grid container gap={1} sx={workingHoursContainerStyles}>
						{groupDays(workingHours).map((item, index) => (
							<Grid container key={index} gap={1.5} alignItems="center">
								<Typography color="text.light" variant="bodyLargeBook" width="50%">
									{item.day}:
								</Typography>
								<Typography color="text.light" variant="bodyLargeBook">
									{item.hours}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Box>
			)}
			{displayOfficeHoursInModal && !!officeInfo.workingHours?.length && (
				<TAGButton
					dataTestId="service_line_hero_hours_button"
					size="S"
					startIcon={<TAGSvgIcon icon="Clock" size={24} />}
					variant="tertiaryHC"
					onClick={() => setShowHoursModal(true)}
				>
					Hours
				</TAGButton>
			)}
			{showHoursModal && !!officeInfo.workingHours?.length && (
				<OfficeHours
					handleClose={() => setShowHoursModal(false)}
					workingHours={officeInfo.workingHours}
					useFacilityBeffeDataSource={useFacilityBeffeDataSource}
				/>
			)}
		</Box>
	);
};

export default OfficeInfo;
